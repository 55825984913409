@import 'src/styles/variables';

.form-group {
  position: relative;

  &:global(.error) {
    label {
      color: $red;
    }

    input {
      border: 1px solid $red;

      &:focus {
        border-color: $red;
      }
    }
  }

  &:global(.errorAbsolte) {
    & + :global(.ErrorText) {
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      width: 100%;
      margin-top: 0;
    }
  }

  &:global(.horizontal) {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 24px;
      flex: none;

      @include phone {
        width: 70px;
      }
    }
    & .title {
      display: inline-block;
      height: 32px;
      line-height: 32px;
      border: 1px solid $tr;
      border-bottom: 1px solid $grey;
      width: 105px;
      padding-left: 8px;
      &:global(.isSaved) {
        background: $savedInputEffect;
      }
    }

    & .body {
      display: inline-block;

      &:hover {
        input {
          cursor: pointer;
          border-bottom: 1px solid $blue;
        }
      }

      @include phone {
        flex: 1;
      }

      input {
        height: 32px;
        border: 1px solid $tr;
        border-bottom: 1px solid $grey;
        padding: 0;
        width: 105px;
        padding-left: 8px;

        @include phone {
          width: 100%;
        }
      }
      &:global(.isSaved) {
        input {
          background: $savedInputEffect;
        }
      }
    }
  }

  &:global(._isDisabled) {
    cursor: not-allowed !important;
    & > * {
      pointer-events: none !important;
    }
    &:global(.horizontal) {
      & .body {
        input {
          color: #828282;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }

  label {
    color: $text-hint;
    font-size: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
  }

  & .title {
    font-size: 14px;
    line-height: 22px;
    color: #222222;
  }

  & .body {
    input {
      background: $white;
      border: 1px solid $grey;
      box-sizing: border-box;
      border-radius: 1px;
      height: 48px;
      width: 100%;
      padding-left: 14px;
      padding-right: 48px;
      font-size: 14px;
      color: $black;
      transition: border 0.1s;

      &:focus {
        outline: none;
        border: 1px solid $blue;
      }
    }

    textarea {
      background: $white;
      border: 1px solid $grey;
      box-sizing: border-box;
      border-radius: 1px;
      min-height: 82px;
      width: 100%;
      font-size: 14px;
      line-height: 26px;
      color: $black;
      transition: border 0.1s;
      padding: 18px 15px 23px 15px;
      resize: none;

      &:focus {
        outline: none;
        border: 1px solid $blue;
      }
    }

    & > div {
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 18px;
        pointer-events: none;
      }
    }
  }

  &__link {
    position: absolute !important;
    top: 0;
    right: 0;
    font-size: 12px;
    line-height: 12px;
    text-align: right;

    a {
      color: $blue;
    }
  }

  &__tooltip {
    margin-left: 8px;
    height: 10px;
    position: relative;
    top: -4px;
  }
}

:global(.PriceInput_wrapper) {
  position: relative;
}

:global(.isLocked) {
  :global(.PriceInput_wrapper) {
    :global(.PriceInput_body) {
      input {
        color: $text-hint;
      }
    }

    & .title {
      color: $text-hint;
    }
  }
}
