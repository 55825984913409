@import 'src/styles/variables';

.container {
  max-width: 576px;
  width: 100%;
  padding: 28px 30px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: $black;
    }
  }

  .descriptions {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #0a0a0a;
    margin-bottom: 16px;

    .link {
      color: $blue;
      margin-left: 4px;
    }
  }

  .content {
    margin-bottom: 40px;
    width: 100%;
    position: relative;
    height: 100%;

    input {
      background-color: transparent;
    }

    .copy {
      position: absolute;
      right: 14px;
      top: 20px;
      bottom: 0;
      margin: 0 auto;
      display: flex;
      height: calc(100% - 20px);
      align-items: center;
      cursor: pointer;

      span {
        color: $blue;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.close {
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f4f5f6;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}
