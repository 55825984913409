.avatar {
  border-radius: 100%;
  position: relative;
  transition: 0.2s;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  & * {
    box-sizing: border-box;
  }

  .img {
    border-radius: 100%;
    width: 24px;
    filter: grayscale(100%);
  }

  .status {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 6px;
    height: 6px;
    background-color: #149c08;
    border: 1px solid #ffffff;
    border-radius: 100%;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.4);
  }
}
