@import 'src/styles/variables';

.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  pointer-events: none;
  z-index: 10000;
  opacity: 0;
  animation: menu-close 0.3s forwards;

  @keyframes menu-open {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes menu-close {
    0% {
      opacity: 1;
    }
    99% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &.isOpened {
    pointer-events: all;
    animation: menu-open 0.3s forwards;

    .side-menu {
      &__bg {
        opacity: 1;
      }

      &__card {
        transform: translateX(0);
      }
    }
  }

  &__bg {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 1;
  }

  &__card {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 330px;
    background: $white;
    transform: translateX(-100%);
    transition: transform 0.3s cubic-bezier(0.02, 0.91, 0.59, 1.1);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow-y: auto;

    .header {
      display: block;

      &__close {
        display: flex;
        height: 58px;
        justify-content: flex-start;
        align-items: center;
        padding: 0 36px;

        svg {
          cursor: pointer;
          color: $black;
        }
      }
    }
    .menu {
      flex: 1 0 auto;

      &__title {
        display: flex;
        height: 48px;
        justify-content: flex-start;
        align-items: center;
        padding: 0 36px;
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        color: $text-hint;
      }

      &__link {
        width: 100%;
        display: flex;
        height: 56px;
        align-items: center;
        padding: 0 32px;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        cursor: pointer;
        transition: background 0.1s;

        &:hover {
          background: #f6f6f6;
          opacity: 1;
        }

        span {
          margin: 0 6px 0 16px;
        }
      }

      &__item {
        width: 100%;
        display: flex;
        height: 56px;
        justify-content: flex-start;
        align-items: center;
        padding: 0 32px;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        cursor: pointer;
        transition: background 0.1s;

        &:hover {
          background: #f6f6f6;
        }

        .svg {
          svg {
            path {
            }
          }
        }

        &.isActive {
          color: $blue;
          font-weight: 500;
          background: #e3ecff;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 3px;
            border-top-right-radius: 1px;
            border-bottom-right-radius: 1px;
            background: $blue;
          }

          .svg {
            svg {
            }
          }
        }

        &.isDisabled {
          color: #b0b0b0;
          pointer-events: none;

          &:hover {
            opacity: 1;
          }
        }

        svg {
          margin-right: 16px;
        }
      }

      &__icon {
        & > div {
          display: flex;
          align-items: center;
        }
      }
    }
    .footer {
      border-top: 1px solid #dddddd;

      &__text {
        display: flex;
        width: 100%;
        height: 60px;
        justify-content: flex-start;
        align-items: center;
        padding: 0 36px;
        font-size: 14px;
        line-height: 17px;
        color: $text-hint;
      }
    }
  }
}
