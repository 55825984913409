@import 'src/styles/variables';

.icon {
  border-radius: 100%;
  padding: 8px;
  display: flex;
  align-items: center;

  &:global(._isSuccess) {
    background: $green;
  }

  &:global(._isError) {
    background: $red;
  }
}

.toast__body {
  font-weight: 400 !important;

  strong {
    font-weight: 500 !important;
  }
  &Text {
    font-weight: 300 !important;
    strong {
      font-weight: 900 !important;
    }
  }
}
