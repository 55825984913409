@import 'src/styles/variables';

.upload {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  &:global(.isDisabled) {
    & > * {
      cursor: not-allowed !important;

      & > * {
        pointer-events: none !important;
      }
    }

    .file {
      color: #222222;
    }

    .links {
      button {
        color: #222222;
      }
    }
  }

  & .file-loader {
    width: 56px;
    height: 56px;
    margin-right: 24px;
    position: relative;

    &:global(.isUpload) {
      .file-loader {
        &__image {
          opacity: 0;
        }

        &__circle {
          display: flex;
        }

        &__arrow {
          display: block;
          animation: arrow-animation 1s infinite forwards;
        }

        &__bar {
          display: block;
          animation: bar-rotate 1s infinite forwards linear;
        }
      }
    }

    &:global(.isComplete) {
      .file-loader {
        &__image {
          opacity: 0;
          animation: image-complete 0.5s 1.5s forwards;
        }

        &__circle {
          display: flex;
          animation: circle-complete 1s 0.8s forwards;
        }

        &__check {
          display: block;

          path {
            animation: check-complete 1s 0.2s forwards;
          }
        }

        &__bar {
          display: block;
          circle {
            animation: bar-complete 1.5s forwards;
          }
        }
      }
    }

    &:global(.isError) {
      .file-loader {
        &__image {
          opacity: 0;
        }

        &__circle {
          display: flex;
          animation: circle-error 1s 0.5s forwards;
        }

        &__X {
          display: block;

          path {
            &:nth-child(1) {
              animation: x-error 1s 0.2s forwards;
            }
            &:nth-child(2) {
              animation: x-error 1s 0.4s forwards;
            }
          }
        }

        &__bar {
          display: block;

          circle {
            animation: bar-error 1.5s forwards;
          }
        }
      }
    }

    &__image {
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      z-index: 0;
      animation-fill-mode: both;
      animation-name: none;

      &:global(.empty) {
        border-radius: 50%;
        border: 1px dashed $text-hint;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          color: $text-hint;
        }
      }

      @keyframes image-complete {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    &__circle {
      justify-content: center;
      align-items: center;
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid #e3ecff;
      border-radius: 50%;
      overflow: hidden;
      z-index: 0;
      animation-fill-mode: both;
      animation-name: none;

      @keyframes circle-complete {
        0% {
          background: rgba(0, 0, 0, 0);
        }
        80% {
          background: #e8fff2;
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      @keyframes circle-error {
        0% {
          background: rgba(0, 0, 0, 0);
        }
        100% {
          background: #ffe3e3;
        }
      }
    }

    &__arrow {
      display: none;
      animation-fill-mode: both;
      animation-name: none;

      @keyframes arrow-animation {
        0% {
          transform: translateY(46px);
        }
        50% {
          transform: translateY(0px);
        }
        100% {
          transform: translateY(-46px);
        }
      }
    }

    &__check {
      display: none;

      path {
        opacity: 0;
        animation-fill-mode: both;
        animation-name: none;

        @keyframes check-complete {
          0% {
            opacity: 0;
            stroke-dasharray: 0 21;
            stroke-dashoffset: -20;
          }
          50% {
            opacity: 1;
            stroke-dasharray: 0 21;
            stroke-dashoffset: -20;
          }
          100% {
            opacity: 1;
            stroke-dasharray: 21;
            stroke-dashoffset: 0;
          }
        }
      }
    }

    &__X {
      display: none;

      path {
        opacity: 0;
        animation-fill-mode: both;
        animation-name: none;

        @keyframes x-error {
          0% {
            opacity: 0;
            stroke-dasharray: 0 20;
          }
          50% {
            opacity: 0;
            stroke-dasharray: 0 20;
          }
          60% {
            opacity: 1;
          }
          100% {
            opacity: 1;
            stroke-dasharray: 20 0;
          }
        }
      }
    }

    &__bar {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      animation-fill-mode: both;
      animation-name: none;

      @keyframes bar-rotate {
        0% {
          transform: rotate(-10deg);
        }
        100% {
          transform: rotate(350deg);
        }
      }

      circle {
        animation-fill-mode: both;
        animation-name: none;

        @keyframes bar-complete {
          0% {
            stroke-dasharray: 30 108;
            stroke: #025dff;
          }
          50% {
            stroke-dasharray: 170;
          }
          98% {
            stroke-dasharray: 170;
            stroke: #00cf3a;
            opacity: 1;
          }
          100% {
            opacity: 0;
            stroke-dasharray: 170;
          }
        }

        @keyframes bar-error {
          0% {
            stroke-dasharray: 30 108;
            stroke: #025dff;
          }
          50% {
            stroke-dasharray: 170;
          }
          100% {
            stroke-dasharray: 170;
            stroke: #ff3636;
          }
        }
      }
    }
  }

  & .text {
    max-width: calc(100% - 80px);
    text-align: left;
  }

  & .remove {
    margin-top: 10px;
    display: flex;
    cursor: pointer;
    font-size: 13px;
    align-items: center;
    width: fit-content;

    svg {
      margin-right: 8px;
    }
  }

  & .info {
    display: flex;

    p {
      font-size: 13px;
      max-width: 80%;
    }

    button {
      transform: rotate(45deg);
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  & .error {
    color: $red;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: 8px;

    svg {
      color: $red;
      margin-left: 6px;
    }
  }

  & .links {
    font-size: 14px;
    line-height: 22px;
    color: #828282;

    a,
    button {
      color: $blue;
      padding: 0;
      margin: 0;
      border: none;
      background: transparent;
      cursor: pointer;
      font-size: 14px;

      &:disabled {
        cursor: default;
        color: #919192;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        text-decoration: underline;
        opacity: 1;
      }
    }

    span {
      margin: 0 4px;
    }
  }

  & .file {
    cursor: pointer;
    color: $blue;
    &:hover {
      opacity: 0.9;
    }

    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }
  }

  & .description {
    padding-top: 4px;

    p {
      font-size: 12px;
      line-height: 20px;
      color: #828282;
    }
  }
}
