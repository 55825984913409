@import 'src/styles/variables';

.form-group {
  position: relative;

  &:global(.horizontal) {
    display: flex;
    align-items: center;

    & .title {
      margin-bottom: 0;
      width: 150px;
    }

    & > div {
      &:last-child {
        flex: 1;
      }
    }
  }

  & .title {
    color: #828282;
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
  }

  & .titleText {
    line-height: 22px;
  }

  label {
    color: $text-hint;
    font-size: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
  }

  strong {
    font-weight: bold;
  }

  &__select {
    display: block;
    position: relative;
    cursor: pointer;
  }

  &__active {
    background: $white;
    border: 1px solid $grey;
    box-sizing: border-box;
    border-radius: 1px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 14px;
    padding-right: 48px;
    font-size: 14px;
    color: $black;
    transition: border 0.1s;
    position: relative;

    &:global(._isOpened) {
      svg {
        transform: rotate(180deg);
      }
    }

    &:global(._isDisabled) {
      pointer-events: none;
      background: #f6f6f6;
      color: #828282;
    }

    &:focus {
      outline: none;
      border: 1px solid $blue;
    }

    svg {
      position: absolute;
      top: calc(50% - 12px);
      right: 10px;
      transition: transform 0.1s;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__dropdown {
    z-index: 1;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: $white;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.1s ease;

    &:global(._isOpened) {
      opacity: 1;
      visibility: visible;
    }
  }

  &__item {
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 14px;
    padding-right: 48px;
    font-size: 14px;
    color: $black;
    background: $tr;
    transition: background 0.1s;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  & > div {
    position: relative;
  }

  &_error {
    label {
      color: $red;
    }

    input {
      border: 1px solid $red;

      &:focus {
        border-color: $red;
      }
    }
  }

  &:global(.isDisabled) {
    pointer-events: none;

    .form-group {
      &__active {
        background: #f6f6f6;
        color: $text-hint;
      }
    }
  }
  .menu {
    background-color: white;
    border-radius: 0;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
    margin-top: 4px;
    position: absolute;
    z-index: 4;
    width: 100%;
  }
  .Dropdown {
    position: relative;
  }
  .Blanket {
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 1;
  }
}
