@import 'src/styles/variables';

@keyframes hideOpacity {
  0% {
    opacity: 1;
    z-index: 10;
  }

  100% {
    opacity: 0;
    z-index: -10;
  }
}

@keyframes showOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.card {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  padding: 17px 22px;
  position: relative;
  transition: box-shadow 0.1s;

  &:global(.isNoHover) {
    &:hover {
      box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
      z-index: inherit;
    }
  }

  &:global(.isLocked) {
    pointer-events: none;

    span,
    input,
    textarea,
    svg,
    label {
      color: $text-hint;
    }

    :global {
      .prompt {
        pointer-events: all;
      }
    }

    .lock {
      display: flex;
      pointer-events: all;
    }

    :global {
      .code-format {
        display: none;
      }
      .CodeMirror {
        background: #f6f6f6;
      }
    }
  }

  &:global(.isButtons) {
    .buttons {
      display: flex;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  &:global(.isTransparent) {
    background: $tr;
  }

  &:global(.isGround) {
    opacity: 0.2;
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
    z-index: 11;
  }

  &__hint {
    position: absolute;
    right: -400px;
    top: 0;
    width: 374px;
    background: #ffffff;
    border-radius: 1px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    z-index: 10;
    box-shadow: 0 2px 25px #0000002e;
    transition: all 0.3s;
    animation: showOpacity 1s forwards;

    &:global(._isClose) {
      animation: hideOpacity 1s forwards;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #0a0a0a;
      vertical-align: middle;

      strong {
        font-weight: 600;
      }
    }

    &::before {
      content: '';
      border: 10px solid transparent;
      border-right: 10px solid rgb(255, 255, 255);
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
    }

    @include tablet {
      display: none;
    }
  }

  &__hintClose {
    cursor: pointer;
    margin-left: 18px;
  }
}

.helpers {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f4f5f6;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  transition: all 0.2s ease;

  &:global(._active) {
    background: #e3ecff;

    svg {
      color: $blue;
    }
  }

  svg {
    color: $black;
  }
}

.lock {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f4f5f6;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;

  &:hover {
    svg {
      color: $black;
    }

    .lock {
      &__info {
        display: block;
      }
    }
  }

  svg {
    color: $text-hint;
  }

  &__info {
    position: absolute;
    display: none;
    top: 50%;
    right: calc(100% + 16px);
    z-index: 1;
    width: 216px;
    background: $white;
    box-shadow: 0 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 1px;
    padding: 12px 14px;
    font-size: 13px;
    line-height: 20px;
    color: $black;
    transform: translateY(-50%);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%) rotate(180deg);
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='30' viewBox='0 0 17 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.748043 15.7203L14.9231 29.3693C15.5583 29.9809 16.6167 29.5307 16.6167 28.6489L16.6167 1.3511C16.6167 0.46928 15.5583 0.0191097 14.9231 0.630747L0.748044 14.2797C0.339527 14.673 0.339525 15.327 0.748043 15.7203Z' fill='white'/%3E%3C/svg%3E%0A");
      width: 17px;
      height: 30px;
    }
  }
}
