.form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.fromTo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 0 23px;

  &:global(._isReverse) {
    flex-direction: row-reverse;
  }

  .select {
    flex: 1;
  }

  .label {
    color: #828282;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
}

.changeBtn {
  flex: none;
  background: var(--primary-blue-10, #e3ecff);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  position: relative;
  top: 10px;
}

.balance_select {
  color: red;
 }
 