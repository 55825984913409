@import 'src/styles/variables';

.list {
  display: flex;
  align-items: center;
  margin-right: 40px;

  @include phone {
    margin-right: 10px;
  }

  & * {
    box-sizing: border-box;
  }

  .more {
    margin-left: 8px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid #ffffff;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.2;
    font-size: 12px;
    line-height: 20px;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.3);
    }
  }
}
