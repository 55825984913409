@import 'src/styles/variables';

.errorAB__wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .errorAB__img {
    img {
      display: block;
      margin: 0 auto;
      height: calc(100vh - 494px);
      min-height: 270px;
      max-width: min-content;
      margin-bottom: 68px;
    }
  }

  .errorAB__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $black;
    margin-bottom: 4px;
  }

  .errorAB__text {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $black;
    max-width: 45vw;
  }
}
