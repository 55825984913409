@import 'src/styles/variables';

@mixin disableState {
  pointer-events: none;

  & .toggle {
    background-color: #cbcbcb;
    border: 1px solid #828282;
  }
  & .button {
    background-color: #828282;
  }
}

.form-group {
  & :global(._isActive) {
    & .toggle {
      background-color: $blue;
      border: 1px solid $blue;
    }
    & .button {
      transform: translateY(-50%) translateX(13px);
      background-color: $white;
    }
  }
  & :global(._isActiveNoBg) {
    & .toggle {
      background-color: white;
      border: 1px solid #828282;
    }
    & .button {
      transform: translateY(-50%) translateX(13px);
      background-color: #828282;
    }
  }
  & :global(._isDisabled) {
    @include disableState;
  }

  &:global(._deactive) {
    .switch {
      pointer-events: none;
    }
  }
  &:global(.mini) {
    & .toggle {
      width: 20px;
      min-width: 20px;
      height: 12px;
    }
    & .button {
      width: 6px;
      height: 6px;
      left: 2px;
    }

    :global(._isActive) {
      & .button {
        left: -3px;
      }
    }
  }

  .label {
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: $text-hint;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  & .switch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    gap: 16px;
  }

  & .toggle {
    flex: none;
    width: 34px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #828282;
    background-color: $tr;
    position: relative;
    transition: background 0.3s, border 0.3s;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }

  & .button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #828282;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    transition: transform 0.3s, background 0.3s;
  }

  & .text {
    display: inline-block;
    font-size: 14px;
    color: #222222;
    cursor: pointer;
  }
}

:global(.isLocked) {
  .form-group {
    @include disableState;
  }
}
