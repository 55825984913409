@import 'src/styles/variables';
.dropdown {
  & .menu {
    width: 100%;
    display: flex;
    height: 56px;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px 0 32px;
    font-size: 16px;
    line-height: 22px;
    color: $black;
    cursor: pointer;
    transition: background 0.1s;

    &:hover {
      background: #f6f6f6;
    }

    & .icon {
      margin-right: 16px;

      svg {
        width: 24px;
        height: auto;
      }

      :global(.svg) {
        svg {
          fill: $black;
        }
      }
    }

    & .text {
      flex: 1 0 auto;
      max-width: calc(100% - 48px);

      & .subtitle {
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
      }
    }

    & .chevron {
      height: 24px;
      transition: transform 0.1s;
    }

    &:global(.isActive) {
      color: $blue;
      font-weight: 500;
      background: #e3ecff !important;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 3px;
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        background: $blue;
      }

      & .icon {
        :global(.svg) {
          svg {
            fill: $blue;
          }
        }
      }

      & .chevron {
        color: $blue;
      }
    }

    &:global(.isOpened) {
      background: #f6f6f6;

      & .chevron {
        transform: rotate(180deg);
      }
    }
  }

  & .list {
    background: #f6f6f6;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s;

    &:global(.isOpened) {
      max-height: 100vh;

      @keyframes item-animation {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(0%);
        }
      }

      @for $i from 1 through 10 {
        .item:nth-child(#{$i}) {
          animation: item-animation
            0.2s
            cubic-bezier(0.02, 0.91, 0.49, 1.05)
            #{0.1s *
            $i}
            forwards;
        }
      }
    }

    & .item {
      transform: translateX(-100%);
      transition: background 0.1s;

      button {
        width: 100%;
        display: flex;
        height: 56px;
        justify-content: flex-start;
        align-items: center;
        padding: 0 16px 0 72px;
        cursor: pointer;
        transition: padding 0.1s;

        &:global(.isActive) {
          span {
            color: $blue;
            font-weight: 500;
          }
        }
        span {
          transition: color 0.1s;
          color: $black;
          cursor: pointer;
          font-size: 16px;
          line-height: 22px;
          text-align: left;
        }
      }

      &:hover {
        background: $white;

        button {
          padding-left: 88px;

          span {
            color: $blue;
          }
        }
      }
    }
  }
}
