.prompt {
  display: inline-block;
  position: relative;
  margin-left: 8px;
  vertical-align: middle;
  // z-index: 2;

  &:global(._isShow) {
    display: none;
  }

  &:global(._isEmpty) {
    display: none;
  }

  &__content {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      circle {
        stroke: #025dff;
      }
      path {
        stroke: #025dff;
      }

      line {
        stroke: #025dff;
      }
    }

    &_title {
      font-size: 14px;
      font-weight: 500;
    }

    &_text {
      font-size: 14px;
      margin-top: 6px;
      font-weight: normal;
      line-height: 22px;
      word-break: break-word;

      strong {
        font-weight: 500;
      }
    }

    &_more {
      margin-top: 6px;
      a {
        display: flex;
        align-items: center;
        font-weight: normal;
        &:hover {
          text-decoration: none;
        }
      }

      &_icon {
        margin-left: 5px;
        display: flex;
        align-items: center;
      }
    }
  }

  &__tooltip {
    div {
      &:last-child {
        white-space: pre-wrap;
        bottom: calc(100% + 10px);
      }
    }
  }
}
