@import 'src/styles/variables';

.wrapper {
  &:global(.extend) {
    & :global(.container) {
      max-width: 1846px;

      @include hd {
        max-width: 1368px;
      }
    }
  }
}
