@import 'src/styles/variables';

.detail {
  :global(.loader) {
    margin: 32px auto 0;
  }
}

.header {
  background: $white;
  height: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  :global(.container) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.title {
  max-width: calc(100% / 12 * 5);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
