@import 'src/styles/variables';

.card {
  margin: 0 auto;
  max-width: 774px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 28px 28px 28px;
  }

  &__title {
    flex: 1 0 auto;
    max-width: calc(100% - 60px);
  }

  &__close {
    margin-left: 24px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
      color: $black;
    }
  }

  &__users {
    padding: 0 28px 28px 28px;

    &_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 0;
      }

      .user__main {
        display: flex;
        align-items: center;
      }

      .user__email {
        margin-left: 16px;
      }
    }
  }
}
