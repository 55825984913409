@import 'src/styles/variables';

.block-header {
  position: sticky;
  top: 0;
  height: 48px;

  &:global(.isFixed) {
    // position: fixed;
    width: 100%;
    z-index: 999;
  }
}

.header {
  background-color: #292929;

  &:global(.loading) {
    width: 100%;
    height: 100%;
  }

  & :global(.container) {
    height: 48px;
    display: grid;
    grid-template-columns: 140px 1fr 140px;
    align-items: center;

    &:global(.isCenter) {
      grid-template-columns: 20% 60% 20%;
      justify-content: center;
    }

    @include tablet {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
    }
  }
}

.back {
  height: 100%;
  font-size: 12px;
  line-height: 14px;
  color: $white;
  display: flex;
  align-items: center;
  justify-self: start;
  margin: 0;
  cursor: pointer;
  margin-left: -24px;
  padding: 0 24px;

  &:hover {
    background: #222222;
    opacity: 1;
  }

  &:active {
    background: #181818;
  }

  span {
    margin-left: 12px;
  }
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > a,
  & > button {
    font-size: 12px;
    line-height: 14px;
    color: $white;
    padding: 0 24px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.1s;
    position: relative;
    cursor: pointer;
    text-align: center;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: #025dff;
      opacity: 0;
    }

    &:hover {
      background: #222222;
      opacity: 1;
    }

    &:active {
      background: #222222;
    }

    &:global(.isDisabled) {
      color: #828282;
      background: #181818;
      cursor: not-allowed;
      pointer-events: none;

      &:hover {
        background: #222;
        opacity: 1;
      }
    }

    &:global(.isActive) {
      background: #2f2f2f;

      &:before {
        opacity: 1;
      }
    }
  }
}

.resize {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  @include laptop {
    display: none;
  }

  button {
    height: 100%;
    width: 62px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: background 0.1s;
    cursor: pointer;

    &:hover {
      background: #2f2f2f;
    }

    &:global(.isActive) {
      background: #2f2f2f;
    }
  }
}
