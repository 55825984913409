@import 'src/styles/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.loaderClient {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 774px;
  width: 100%;
}

.title {
  flex: 1 0 auto;
  max-width: calc(100% - 60px);
}
.close {
  margin-left: 24px;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f4f5f6;
  justify-content: center;
  align-items: center;

  svg {
    cursor: pointer;
    color: $black;
  }
}

.form {
  padding-top: 32px;

  form > * {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  .reports {
    margin-top: 32px;

    & :global(.switch) {
      display: inline-block;
    }

    h4 {
      margin-top: 32px;
      margin-bottom: 28px;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    & .helper {
      margin-top: -20px;
      margin-bottom: 3px;
      font-size: 13px;
      line-height: 20px;
      color: #828282;
    }

    & .list {
      margin-top: 25px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 21px 0;

      & > div {
        &:last-child {
          border-color: transparent;
          padding-bottom: 0;
        }
      }

      &:global(._disabled) {
        & .group {
          pointer-events: none;
          & > span {
            color: $text-hint;
            font-weight: 400;
          }
        }

        & .select {
          & > div {
            &:first-child {
              span {
                color: $text-hint;
              }

              svg {
                color: $text-hint;
              }
            }
          }
        }
      }

      & .group {
        padding-bottom: 13px;
        border-bottom: 1px #ebebeb solid;
        display: grid;
        grid-template-columns: 63% auto;
        align-items: center;

        & > span {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }

      & .select {
        display: inline-block;

        & > div {
          &:first-child {
            height: initial;
            padding-left: 3px;
            padding-right: 36px;
            border: none;

            & > svg {
              font-size: 24px;
              width: 24px;
              height: 24px;
              top: calc(50% - 12px);
            }
          }

          &:last-child {
            width: 120%;
            left: 50%;
            right: initial;
            transform: translateX(-50%);

            & > div {
              height: 40px;
              padding: 12px;
            }
          }
        }
      }
    }
  }
}

.edit {
  background: #fff6d6;
  display: flex;
  align-items: center;
  padding: 10px 18px;
  margin-top: 16px;

  p {
    margin-left: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #ff9c08;
  }
}

.buttons {
  margin-top: 40px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  button {
    &:global(.loading) {
      border-color: transparent;
      background: #dadce0;

      & :global(.loader) {
        background: transparent;
        width: 36px;
        height: 36px;
      }
    }
  }
}
