@import 'src/styles/variables';

.form-group {
  position: relative;

  &:global(._isDisabled) {
    cursor: not-allowed;

    & > div {
      pointer-events: none;
    }

    span {
      color: #828282;
    }

    svg {
      color: #828282;
    }
  }

  &:global(._isTarget) {
    .form-group {
      &__select {
        min-width: 140px;
      }
      &__active {
        font-weight: 400;
        line-height: 22px;

        span {
          flex: 1 0 auto;
        }
      }
    }
  }

  &__select {
    position: relative;
    min-width: fit-content;
  }

  &__active {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $black;
    white-space: nowrap;
    cursor: pointer;

    &:global(._isOpened) {
      .form-group__chevron {
        transform: rotate(180deg);
      }
    }
  }

  &__calendar {
    margin-right: 8px;
  }

  &__chevron {
    margin-left: 8px;
    transition: 0.2s;
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 8px);
    width: 240px;
    right: 0;
    background: $white;
    padding: 2px 0;
    z-index: 102;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 1px;
    display: none;

    &:global(._isOpened) {
      display: block;
    }
  }

  &__item {
    background: $white;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    padding: 0 14px;
    transition: background 0.1s;

    &:hover {
      background: #f6f6f6;
      cursor: pointer;
    }

    &:global(._isDisabled) {
      color: $text-hint;

      &:hover {
        cursor: not-allowed;
        background: $white;
      }
    }
  }
}
