@import 'src/styles/variables';

.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 90000;
  display: block;

  & .bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
  }

  & .content {
    pointer-events: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
  }

  & .dialog {
    margin: 20px auto;
    width: 100%;
    pointer-events: none;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      pointer-events: all;
    }
  }
}

.isHidden {
  display: none;
}
