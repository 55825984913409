@import 'src/styles/variables';

.date-range-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  pointer-events: all;
  z-index: 99999;

  &__bg {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.1s;
    opacity: 1;
    z-index: 1;
  }

  &__content {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    z-index: 2;
    pointer-events: none;
    padding: 20px;
    overflow: auto;
  }

  &__card {
    max-width: 940px;
    width: 100%;
    background: $white;
    pointer-events: all;
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
    border-radius: 1px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 28px;

      &__title {
        flex: 1 0 auto;
        max-width: calc(100% - 60px);
      }

      &__close {
        margin-left: 24px;
        display: flex;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #f4f5f6;
        justify-content: center;
        align-items: center;

        svg {
          cursor: pointer;
          color: $black;
        }
      }
    }
    .calendar {
      text-align: center;
      margin-top: -17px;

      .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: #f6f6f6;
      }

      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: #e3ecff !important;
        color: $black;
      }
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        &.DayPicker-Day--start,
        &.DayPicker-Day--end {
          background-color: #025dff !important;
          color: $white !important;
        }
      }
      .DayPicker-Day.DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
        color: #828282 !important;
        cursor: not-allowed;
        pointer-events: none;
      }
      .DayPicker-Day {
        border-radius: 0 !important;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #222222;
        width: 36px;

        &.DayPicker-Day--today {
          font-weight: normal;
        }
      }
      .DayPicker-Caption {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: $black;
      }
      .DayPicker-Weekday {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #828282;
      }
      .DayPicker-Months {
        min-height: 285px;
      }
      .DayPicker-wrapper {
        .calendar-navbar {
          button {
            cursor: pointer;

            svg {
              vertical-align: middle;
              color: #222222 !important;
            }
          }

          @media only screen and (max-width: 960px) {
            left: 25px;
            right: 25px;
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 28px 28px;

      & > * {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.statistics-loading {
  padding: 60px 0;
  display: grid;
  align-items: center;
  justify-content: center;
}
