@import 'src/styles/variables';

.card {
  max-width: 774px;
  width: 100%;

  &_header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
    gap: 0 24px;
  }

  &_title {
    flex: 1 0 auto;
    max-width: calc(100% - 60px);
    color: var(--neutral-black, #222);
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }

  &_close {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    &:hover {
      background: darken(#f4f5f6, 5%);
    }

    svg {
      cursor: pointer;
      color: black;
    }
  }

  &_form {
    margin-bottom: 40px;
    line-break: anywhere;

    strong {
      font-weight: 600;
    }
  }

  &_actions {
    display: flex;
    justify-content: flex-end;
    gap: 0 20px;
  }
}
