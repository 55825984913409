@import 'src/styles/variables';

.error {
  display: block;
  padding-top: 48px;
  padding-bottom: 48px;

  &:global(.isError) {
    .img {
      img {
        max-width: 40vw;
        min-height: fit-content;
        height: auto;
        @include tablet {
          max-width: 80vw;
        }
      }
    }
  }

  & .img {
    img {
      display: block;
      margin: 0 auto;
      height: calc(100vh - 510px);
      min-height: 280px;
      max-width: min-content;
    }
  }

  & .content {
    margin-top: 24px;

    h2 {
      text-align: center;
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }

    p {
      margin-top: 8px;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      text-align: center;
    }
  }
}
