@import 'src/styles/variables';

.wrapper {
  display: flex;
  justify-content: center;
  & .logo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid #f4f5f6;
    margin: 0 auto 40px;
    align-self: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
