@import 'src/styles/variables';

.container {
  overflow: auto;
  background: white;
  display: grid;
  grid-template-columns: 484px auto;

  &:global(._isNewYear) {
    background-color: #f4f4f4;

    & .left {
      & .logo {
        max-width: calc(100% - 120px);

        @include laptop {
          max-width: 382px;
        }
      }
    }

    & .right {
      background-image: url(../../assets/snowflakes.svg);
      background-size: cover;
      background-position: center;
    }
  }

  @include uhd {
    grid-template-columns: 484px auto;
  }

  @include laptop {
    position: relative;
    height: 100%;
    min-height: 100vh;
    grid-template-rows: 196px 1fr;
    overflow: auto;
    grid-template-columns: 1fr;
  }

  & .left {
    min-height: 100vh;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color: #f6f6f6;
    position: sticky;

    & .logo {
      position: absolute;
      top: 32px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      max-width: calc(100% - 250px);
      width: 100%;

      img {
        width: 100%;
        vertical-align: middle;
      }
    }

    & .slogan {
      &:before {
        border-top: 3px $white solid;
      }
    }

    &:global(_blue) {
      background: linear-gradient(152.83deg, #0747a6 0.91%, #186ce8 101.68%),
        #186ce8;
    }

    @include laptop {
      min-height: 196px;

      & .slogan {
        display: none;
      }

      & .logo {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 329px;
      }
    }

    @include phone {
      background-position: center 62%;
      background-size: 100vw;
    }
  }

  & .slogan {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $white;
    position: absolute;
    bottom: 32px;
    left: 32px;
    z-index: 1;

    &:before {
      position: absolute;
      content: '';
      top: -5px;
      left: 0;
      width: 21px;
      border-top: 3px #bf1919 solid;
    }
  }
}

.right {
  min-height: 100vh;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 60px 24px;
  justify-items: center;
  align-items: center;
  padding: 75px 0 32px;
  position: relative;

  .christmasCat {
    position: fixed;
    bottom: 0;
    right: 18%;
    width: 220px;
    height: auto;
  }

  @include laptop {
    min-height: fit-content;
    padding: 25px 0 32px;
  }
}

.content {
  max-width: 502px;
  width: 100%;
  margin-bottom: 40px;

  @include laptop {
    margin-bottom: 0;
  }

  & .card {
    padding: 80px 48px;
  }

  @include laptop {
    & .card {
      padding: 28px 16px 34px;
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 4;
}

.form {
  & :global(.input-form-group) {
    margin-bottom: 24px;
  }

  & :global(.button) {
    width: 100%;
  }
}

.title {
  text-align: center;
  margin-bottom: 24px;
}

.changeform {
  position: absolute;
  top: 32px;
  right: 32px;
  text-align: right;

  a {
    color: $blue;
  }

  &:global(.nodesktop) {
    display: none;
  }

  @include laptop {
    display: none;

    &:global(.nodesktop) {
      display: block;
      top: auto;
      bottom: 55px;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}

.offer {
  margin-top: 20px;
  font-size: 14px;
  line-height: 22px;
  color: #828282;
  text-align: center;

  @include laptop {
    margin-top: 0;
  }

  a {
    color: $blue;
  }
}

.notifications {
  margin-bottom: 40px;

  div,
  a {
    display: grid;
    align-items: center;
    grid-template-columns: 24px auto auto;
    grid-gap: 14px;
    padding: 10px 16px 10px;
    background: #e3ecff;

    svg,
    span {
      color: #025dff;
    }

    svg {
      &:last-child {
        justify-self: flex-end;
      }
    }

    &:first-child {
      background: #c8fcc3;
      margin-bottom: 8px;

      svg,
      span {
        color: #149c08;
      }
    }
  }
}

.input_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding-bottom: 14px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 24px;

  &:global(.mr40) {
    margin-top: 40px;
  }
}

.error {
  background: #ffe3e3;
  min-height: 40px;
  padding: 10px 18px;
  display: grid;
  grid-template-columns: 20px auto;
  grid-gap: 14px;
  align-items: center;
  margin-bottom: 40px;

  span {
    color: #ff3636;
    font-size: 14px;
    line-height: 22px;
  }

  svg {
    color: #ff3636;
  }
}

:global(.input-form-group) {
  .toggle_pass {
    pointer-events: all !important;
    user-select: none;
    &:hover {
      cursor: pointer;
    }
  }
}

.forgotPass {
  position: relative;

  & > a {
    position: absolute;
    right: 0;
    top: 0;
    color: $blue;
    font-size: 12px;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 4;
}
