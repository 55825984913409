@import 'src/styles/variables';

.language {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .langIcon {
    margin-right: 8px;
  }

  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $black;
  }

  & > svg {
    margin-right: 8px;
  }

  & .container {
    font-weight: 400;
    margin-left: 4px;
    position: relative;
    cursor: pointer;

    &:global(.isOpened) {
      .dropdown {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: translateY(0);
      }

      .btn {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }

  & .btn {
    position: relative;
    display: flex;
    align-items: center;

    svg {
      transition: transform 0.1s ease;
    }
  }

  & .dropdown {
    position: absolute;
    bottom: calc(100% + 8px);
    right: -20px;
    min-width: 180px;
    background: #ffffff;
    box-shadow: 0px 7px 50px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    pointer-events: none;
    transform: translateY(20px);
    transition: opacity 0.1s, transform 0.1s;

    & .item {
      min-height: 40px;
      vertical-align: middle;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      display: flex;
      align-items: center;
      padding: 0 14px;
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: opacity 0.1s;

      &:disabled {
        opacity: 0.7;
        cursor: default;
      }

      &:hover {
        opacity: 0.7;
      }

      &:global(.isActive) {
        opacity: 0.5;
      }
    }
  }
}
