.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.link-style {
  font-size: 1px;
  opacity: 0;
  height: 1px;
  position: absolute;
  top: -300px;
  display: flex;
}
