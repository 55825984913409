.balance_option {
  min-height: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  height: fit-content !important;

  &:global(._isSelected) {
    max-width: calc(100% - 8px) !important;
    position: absolute !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    padding-left: 14px !important;
    padding-right: 12px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  &_label {
    font-size: 14px !important;
    line-height: 18px !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    word-break: break-all !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;

    strong {
      font-weight: 500 !important;
    }
  }
  &_price {
    font-size: 12px !important;
    line-height: 18px !important;
  }
}

:global(.balanceSelect) {
  :global(.new_select__value-container--has-value) {
    height: 48px !important;
  }
}

