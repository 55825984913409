@import 'src/styles/variables';

.balance {
  display: flex;
  align-items: center;
  gap: 0 12px;
  border-radius: 1px;
  cursor: pointer;
  padding: 4px 6px;

  .balanceValue {
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 0 6px;
  }

  .balanceUpdate {
    stroke: #ffffff;
    & > div {
      display: flex;
      align-items: center;
    }
  }

  &:global(._bg) {
    background: var(--neutral-grey-60, #292929);
  }

  &:global(._isEmpty) {
    margin-right: 0;
    padding: 0;
    width: 0;
    height: 0%;
  }

  &:global(._isRevert) {
    flex-direction: row-reverse;

    .balanceValue {
      margin-right: 0;
    }

    .balanceUpdate {
      margin-right: 12px;
    }

    .loader {
      margin-left: 12px;
      margin-right: 0;
    }
  }

  &:global(._isWhite) {
    background: #ffffff;

    .balanceValue {
      color: #000;
    }

    .balanceUpdate {
      stroke: #b9b9b9;

      svg {
        filter: contrast(0.6);
      }
    }

    .loader {
      .loaderItem {
        background: #4d4c4c00;
      }
    }

    @keyframes opacityLoader {
      0% {
        background-color: #4d4c4c;
      }

      100% {
        background-color: #4d4c4c00;
      }
    }
  }

  &:global(._isTransparent) {
    background: $tr;

    .balanceValue {
      color: #000;
    }
  }

  &:global(._isRedText) {
    .balanceValue {
      color: #eb5757;
    }
  }

  .loader {
    display: flex;
    align-items: center;
    margin-right: 12px;

    .loaderItem {
      width: 8px;
      height: 2px;
      background: rgba(255, 255, 255, 0);
      margin-right: 2px;
      background-repeat: 2px;
      animation-name: opacityLoader;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-direction: normal;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .item1 {
      animation-delay: 0s;
    }
    .item2 {
      animation-delay: 0.1s;
    }
    .item3 {
      animation-delay: 0.2s;
    }
    .item4 {
      animation-delay: 0.3s;
    }
    .item5 {
      animation-delay: 0.4s;
    }
    .item6 {
      animation-delay: 0.5s;
    }
    .item6 {
      animation-delay: 0.6s;
    }
    .item7 {
      animation-delay: 0.7s;
    }
  }

  @keyframes opacityLoader {
    0% {
      background-color: rgba(255, 255, 255, 0);
    }

    100% {
      background-color: rgb(255, 255, 255);
    }
  }
}
.tooltipHint {
  padding: 2px 5px !important;
}
