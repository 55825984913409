@import 'src/styles/variables';

.button {
  min-width: 123px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $blue;
  color: $white;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 400;
  border: 1px transparent solid;
  border-radius: 1px;
  cursor: pointer;
  transition: all 0.1s ease 0s;

  @include tablet {
    min-width: fit-content;
    height: 40px;
    padding: 0 12px;
  }

  &:hover {
    background: darken($blue, 5%);
  }

  &:active {
    background: darken($blue, 10%);
  }

  &.button_lightForDomain {
    background: #1782FF;

    &:hover {
      background: darken(#1782FF, 5%);
    }
  }

  &.button_white {
    background: $tr;
  }

  &.button_grey {
    background: #ddd;

    &.button_transparent {
      background: $tr;
      border: 1px solid #ddd;
      color: $black;

      svg {
        color: $black;
      }

      &:hover {
        background: $light-blue;
        border: 1px solid $blue;
        color: $blue;
      }
    }
  }

  &.button_lightgrey {
    background: #f4f5f6;
    color: $black;
    border: 1px solid #f4f5f6;

    svg {
      color: $black;
    }

    &:hover {
      svg {
        color: $blue;
      }

      background: $light-blue;
      border: 1px solid $blue;
      color: $blue;
    }
  }

  &.button_lightblue {
    background: #e3ecff;
    color: $blue;
    border: 1px solid #e3ecff;

    svg {
      color: $blue;
    }

    &:hover {
      background: #bed3ff;
      border: 1px solid #bed3ff;
    }
  }

  &.button_transparent {
    background: $tr;
    color: $blue;
    border: 1px solid $blue;

    svg {
      color: $blue;
    }

    &:hover {
      background: $light-blue;
    }
  }

  &.button_red {
    color: #ff3636;
    border: 1px solid #ff3636;

    &:hover {
      color: #ff3636;
      background: #f5e0e0;
      border: 1px solid #ff3636;
    }
  }

  &_hasIcon {
    span + span {
      margin-left: 9px;
    }
  }

  &.button_autoWidth {
    min-width: auto;
  }

  &.button_noBorder {
    border: none;

    &:hover {
      background: $tr;
    }
  }

  &:disabled {
    background: #e2e2e2;
    border-color: #828282;
    color: #828282;
    cursor: not-allowed;

    &:hover {
      background: #e2e2e2;
      border-color: #828282;
      color: #828282;
    }

    svg {
      color: #828282;
    }
  }

  .icon {
    display: flex;
    align-items: center;
  }
}
