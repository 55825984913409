@import 'src/styles/variables';

.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 404px;

  &__icon {
    width: 74px;
    height: 74px;
    background: #e3ecff;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
  }
}

.chart {
  padding: 28px 0;
  width: 100%;

  &__legend {
    padding: 0 22px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 32px;

    &:first-child {
      margin-left: 0;
    }

    div {
      width: 14px;
      height: 14px;
      background: #40baff;
      border-radius: 1px;
    }

    span {
      margin-left: 9px;
      font-size: 14px;
      line-height: 22px;
      color: #222222;
    }
  }

  :global(.loader) {
    height: 326px;
    margin-top: 0 !important;
  }
}

.table {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 21px 22px;
  }

  &__title {
  }

  &__actions {
  }

  &__hint {
    margin-left: 5px;
    vertical-align: middle;
    position: relative;

    &:hover {
      opacity: 1;

      svg {
        color: $blue;
      }

      div {
        display: flex;
      }
    }

    div {
      display: none;
      justify-content: center;
      align-items: center;
      width: 150px;
      min-height: 30px;
      padding: 0 10px;
      background: #222222;
      border-radius: 2px;
      position: absolute;
      bottom: calc(100% + 20px);
      right: -67px;

      &:after {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='31' height='14' viewBox='0 0 31 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.853 13.451L1.07724 1.76251C0.367431 1.16024 0.793331 0 1.72422 0H29.2758C30.2067 0 30.6326 1.16024 29.9228 1.76251L16.147 13.451C15.7738 13.7677 15.2262 13.7677 14.853 13.451Z' fill='%23222222'/%3E%3C/svg%3E%0A");
        width: 31px;
        height: 14px;
        top: calc(100% - 2px);
        left: calc(50% - 15px);
      }
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      text-transform: none;
    }
  }

  table {
    width: 100%;

    thead {
      tr {
        &:nth-child(1) {
          border-bottom: 1px solid #ebebeb;
        }

        &:first-child {
          border-top: 1px solid #ebebeb;
        }

        &:global(.sum) {
          th {
            font-size: 14px;
            line-height: 22px;
            text-transform: none;
          }
        }

        th {
          padding: 15px 22px;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.03em;
          text-transform: uppercase;
          color: #222222;
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        vertical-align: center;

        &:hover {
          background: #f6f6f6;
        }

        td {
          padding: 15px 22px;
          font-size: 14px;
          line-height: 22px;
          color: #222222;
          text-align: left;
          vertical-align: middle;
        }
      }
    }
  }
}
