$blue: #025dff;
$light-blue: #e3ecff;
$dark-blue: #002b97;
$red: #ff3636;
$red-bg: #ffe3e3;
$orange: #ff9c08;
$orange-bg: #fff2c5;
$green: #149c08;
$green-bg: #c8fcc3;
$yellow: #fff7db;
$white: #fff;
$white-hover: transparentize(#f6f6f6, 0.2);
$black: #222;
$grey: #ddd;
$grey-bg: #f4f4f4;
$tr: #fff0;
$whiteblue: #e3ecff;
$gray-bg: #f4f4f4;
$moregray: #292929;
/* Подсветка строк в списках при наведении*/
$backlightItems: #e6fce4;
$savedInputEffect: #e6fce4;

$text-main: #222;
$text-hint: #828282;
$text-form: #cbcbcb;
$text-disable: #b0b0b0;

@mixin rubik {
  font-family: 'Rubik', sans-serif;
}

@mixin code {
  font-family: 'Source Code Pro', monospace;
}

// media sizes
$desktopFullHD: 1920px;
$desktopXL: 1440px;
$laptop: 1366px;
$tablet: 1024px;
$phone: 480px;

@mixin desktopFull {
  @media only screen and (max-width: $desktopFullHD) {
    @content;
  }
}

@mixin desktopXL {
  @media only screen and (max-width: $desktopXL) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (max-width: $laptop) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin phone {
  @media only screen and (max-width: $phone) {
    @content;
  }
}

@mixin iphoneX {
  @media only screen and (min-device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
    only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
    only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin hd {
  @media only screen and (max-width: 1919px) {
    @content;
  }
}

@mixin uhd {
  @media only screen and (min-width: 2200px) and (max-width: 3839px) {
    @content;
  }
}

@mixin loadItemKeyframe {
  @keyframes load-item {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0.8;
    }
    70% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin campaignGrid {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: auto 370px;

  @include tablet {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
}

@mixin fixResizeCopyModal {
  max-height: 540px;

  @include laptop {
    max-height: 360px;
  }

  @include tablet {
    max-height: 300px;
  }

  @media only screen and (max-height: 900px) {
    max-height: 300px;
  }

  @media only screen and (max-height: 670px) {
    max-height: 200px;
  }

  @media only screen and (max-height: 500px) {
    max-height: 100px;
  }
}

/*переменные для размеров контейнера*/
$containerWidth: 1308px;
$containerPaddingRight: 67px;
$containerPaddingLeft: 67px;

$containerWidth-mob: 1204px;
$containerPaddingRight-mob: 15px;
$containerPaddingLeft-mob: 15px;

$extendContainerWidth: calc(100vw - 20px);
$extendContainerPaddingRight: 67px;
$extendContainerPaddingLeft: 67px;

$fullContainerWidth: calc(100vw - 20px);
$fullContainerPaddingRight: 10px;
$fullContainerPaddingLeft: 10px;

/** Подсветка для последнего посещеннего элемента */
@mixin last-edited-row-animation {
  @keyframes last-edited-row {
    0% {
      background: #f4f5f6;
    }

    50% {
      background: $white;
    }

    100% {
      background: $white;
    }
  }
  animation: last-edited-row 3s ease-out forwards;
}

@mixin last-edited-indicator-animation {
  @keyframes last-edited-indicator {
    0% {
      opacity: 1;
      top: 3px;
      bottom: 3px;
    }

    10% {
      opacity: 1;
      top: 2px;
      bottom: 2px;
    }

    100% {
      opacity: 0;
      top: 50%;
      bottom: 50%;
    }
  }
  animation: last-edited-indicator 6s ease-out forwards;
}

@mixin indicator {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 2px;
  width: 4px;
  border-radius: 2px;
  background: $green;
  display: block;
  opacity: 0;
  z-index: 15;
}