@import 'src/styles/variables';

.bg {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.1s;
  opacity: 1;
  z-index: 1;
}

.content {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 2;
  pointer-events: none;
  padding: 20px;
  overflow: auto;
}

.card {
  margin: 0 auto;
  max-width: 546px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 28px;
}

.close {
  margin-left: 24px;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f4f5f6;
  justify-content: center;
  align-items: center;

  svg {
    cursor: pointer;
    color: $black;
  }
}

.text {
  padding: 0 28px;
  font-size: 14px;
  line-height: 22px;
  color: #0a0a0a;

  strong {
    font-weight: 500;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 28px 28px;

  & > * {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}
