@import 'src/styles/variables';

@mixin btnStyles {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  background: #f6f6f6;
  cursor: pointer;
}

.profile_menu {
  position: relative;

  &__view {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }

  &:global(._isOpened) {
    .profile_menu {
      &__chevron {
        transform: rotate(180deg);
      }
      &__dropdown {
        opacity: 1;
        visibility: visible;
        transform: translateY(0) translateX(0%);
      }
    }
  }

  &__icon {
    display: block;
    height: 38px;
    width: 38px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;

    &.isDefault {
      border-radius: 0;
    }

    @include phone {
      display: none;
    }
  }

  &__text {
    display: block;
    margin-left: 8px;

    @include phone {
      margin-left: 0;
    }
  }

  &__login {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: $white;
    display: flex;
    align-items: center;

    @include phone {
      justify-content: flex-end;
      font-size: 11px;
      line-height: 11px;
    }
  }

  &__balance {
    font-size: 13px;
    line-height: 20px;
    color: $white;
    span {
      color: #f4f4f4;
    }

    @include phone {
      text-align: right;
      font-size: 11px;
      line-height: 11px;
      padding-right: 10px;
    }
  }

  &__chevron {
    transition: transform 0.1s;
  }

  &__dropdown {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    background: $white;
    border-radius: 1px;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    top: calc(100% + 12px);
    z-index: 1000;
    transition: all 0.2s ease 0s;
    right: 0;
    width: auto;
    transform: translateY(-5px) translateX(0%);

    @include phone {
      left: initial;
      min-width: 150px;
    }

    &:before {
      content: '';
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0L0 8H16L8 0Z' fill='white'/%3E%3C/svg%3E%0A");
      width: 16px;
      height: 8px;
      top: -8px;
      right: 40px;

      @include phone {
        right: 13px;
        left: initial;
      }
    }

    &::after {
      content: '';
      width: calc(100% + 100px);
      height: calc(100% + 30px);
      top: 0;
      right: -42px;
      position: absolute;
      z-index: -1;
    }

    & .fornt {
      & .item {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 0 15px;
        min-height: 48px;
        background: $tr;
        transition: background 0.1s;
        cursor: pointer;
        text-wrap-mode: nowrap;

        &:hover {
          background: #f6f6f6;
        }

        svg {
          color: $text-main;
          color: #222;
          width: 24px;
          height: auto;
          flex: none;
        }

        span {
          font-size: 14px;
          line-height: 22px;
          color: $text-main;
        }
      }
    }

    & .change {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
      padding-top: 10px;
      min-width: 320px;

      &_current {
        padding: 6px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 14px;
        transition: 0.3s;

        &:hover {
          background: #f6f6f6;
        }

        & .info {
          display: flex;
          align-items: center;
          gap: 10px;
          &_user {
            width: 30px;
            height: 30px;
            @include btnStyles;
            background: none;
            cursor: default;
            border: 1px solid #828282;
          }
          &_data {
            span {
              display: block;
              font-size: 13px;
              line-height: 20px;
              line-break: auto;
            }
            strong {
              display: block;
              font-size: 12px;
              line-height: 18px;
              color: #828282;
              line-break: auto;
            }
          }
        }

        & .exit {
          cursor: pointer;
        }
      }
      &_other {
        padding: 11px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 16px;
        }
      }
      &_add {
        width: 30px;
        height: 30px;
        @include btnStyles;
      }
      &_list {
        max-height: 277px;
        overflow: auto;

        &:global(.isLoading) {
          overflow: initial;
        }

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
        }
      }
      &_exit {
        display: flex;
        align-items: center;
        gap: 14px;
        padding: 15px 20px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background: #f6f6f6;
        }
      }
      & .acc {
        padding: 7px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        transform: 0.3s;
        cursor: pointer;

        &:global(.isLoadingState) {
          .acc_info {
            flex: 1;

            &:hover {
              background: none;
            }
          }

          :global(.load-item) {
            &:first-child {
              width: 30px;
              height: 30px;
              border-radius: 100%;
            }
            &:last-child {
              height: 56px;
              flex: 1 1 0%;
            }
          }
        }

        &:hover {
          background: #f6f6f6;

          .acc_exit {
            opacity: 1;
          }
        }

        &_info {
          display: flex;
          align-items: center;
          gap: 14px;
          line-break: auto;
        }

        &_icon {
          @include btnStyles;
          width: 30px;
          height: 30px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          cursor: default;

          &:global(._isDefault) {
            background-image: none;
            cursor: default;
            border: 1px solid #828282;
          }
        }

        &_data {
          span {
            display: block;
            font-size: 13px;
            line-height: 20px;
          }
          strong {
            display: block;
            font-size: 12px;
            line-height: 18px;
            color: #828282;
          }
        }

        &_exit {
          opacity: 0;
          transition: opacity 0.3s;
          cursor: pointer;

          &:hover {
            svg {
              color: $blue;
            }
          }

          svg {
            color: #828282;
          }
        }
      }
    }

    & .login {
      padding: 20px;
      min-width: 320px;

      &_header {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
      }

      &_title {
        font-size: 16px;
        line-height: 22px;
        flex: 1;
        text-align: center;
      }

      &_form {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
      }

      & .error {
        background: #ffe3e3;
        min-height: 40px;
        padding: 10px 18px;
        display: grid;
        grid-template-columns: 20px auto;
        grid-gap: 14px;
        align-items: center;

        span {
          color: #ff3636;
          font-size: 14px;
          line-height: 22px;
        }

        svg {
          color: #ff3636;
        }
      }

      :global(.input-form-group) {
        & .toggle_pass {
          pointer-events: all;
          cursor: pointer;
        }
      }
    }

    & .back {
      width: 20px;
      height: 20px;
      @include btnStyles;
    }
  }
}
