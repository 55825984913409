@import './reset';
@import './variables';

#root,
body,
html {
  height: 100%;
  scroll-behavior: smooth !important;
}

#modal-root {
  position: relative;
  z-index: 1050;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $black;
  background: $grey-bg;
  min-width: 900px;
}

* {
  box-sizing: border-box;
  outline: none;

  scrollbar-width: auto;
  scrollbar-color: #dddddd;
}

h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: $black;
}

h2 {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $black;
}

h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: $black;
}

h4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

p {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: $black;
}

button {
  background: none;
  font-family: 'Rubik', sans-serif;
  border: none;
  padding: 0;
  margin: 0;
  font-weight: normal;

  &:focus,
  &:active {
    outline: none;
  }
}

a {
  transition: all 0.1s ease;
  color: $black;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

img {
  max-width: 100%;
  height: auto;
}

textarea,
input {
  @include rubik;
}

code,
.code {
  @include code;
  min-height: 78px !important;
}

.container {
  max-width: 1204px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
  transition: all 0.1s;

  @include phone {
    padding-left: 10px;
    padding-right: 10px;
  }

  &.maxWidth_774 {
    max-width: 774px;
  }

  &.resized {
    max-width: $containerWidth;
    padding-right: $containerPaddingRight;
    padding-left: $containerPaddingLeft;

    @include laptop {
      max-width: $containerWidth-mob;
      padding-right: $containerPaddingRight-mob;
      padding-left: $containerPaddingLeft-mob;
    }

    &.extend {
      max-width: $extendContainerWidth;
      padding-right: $extendContainerPaddingRight;
      padding-left: $extendContainerPaddingLeft;

      @include laptop {
        max-width: $containerWidth-mob;
        padding-right: $containerPaddingRight-mob;
        padding-left: $containerPaddingLeft-mob;
      }
    }

    &.full {
      max-width: $fullContainerWidth;
      padding-right: $fullContainerPaddingRight;
      padding-left: $fullContainerPaddingLeft;
    }
  }
}

.Toastify {
  &__toast-container {
    width: auto !important;
    z-index: 99999 !important;
  }

  &__toast {
    min-width: 388px !important;
    max-width: 910px !important;
    padding: 18px 18px 18px 18px !important;
    min-height: 70px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    font-family: 'Rubik', sans-serif !important;
    line-height: 22px !important;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15) !important;
    margin-left: auto;
    gap: 10px;

    @include tablet {
      width: 600px !important;
      padding: 10px !important;
      min-height: 62px !important;
    }

    svg {
      flex: none;
    }

    &-icon {
      width: auto !important;
      margin-inline-end: 0;
    }
    &.wide {
      width: 520px !important;
      align-items: flex-start !important;

      @include tablet {
        width: 800px !important;
      }
    }

    &--default {
      color: #898989 !important;
      background: #fff !important;
      border-left: 4px solid #898989 !important;
    }

    &--error {
      color: $red !important;
      background: #fff !important;
      border-left: 4px solid $red !important;
      border-radius: 0 !important;
    }

    &--success {
      color: $black !important;
      background: #fff !important;
      border-left: 4px solid $green !important;
      border-radius: 0 !important;
    }

    &--warning {
      color: $black !important;
      background: #fff !important;
      border-left: 4px solid #FF9C08 !important;
      border-radius: 0 !important;
    }

    &--info {
      color: $black !important;
      background: #fff !important;
      border-left: 4px solid $blue !important;
    }

    &--black {
      background: #222222 !important;
      box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.27) !important;
      border: none !important;
      color: $white !important;
      font-size: 14px;
      line-height: 22px;
      font-weight: normal !important;
      text-align: center;

      .Toastify__progress-bar {
        visibility: hidden;
      }

      svg {
        display: none;
      }
    }

    &--delete {
      width: 100% !important;

      .Toastify__toast-body {
        margin-right: 0px !important;
      }
    }
  }

  &__toast-body {
    word-break: break-word;
    padding: 0 !important;
    gap: 10px;
  }
}

.DayPickerInput {
  width: 100%;

  .DayPickerInput-Overlay {
    width: 100%;
    margin-top: 4px;
    z-index: 1000;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
  }

  .DayPicker {
    width: 100%;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #f6f6f6;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #025dff !important;
    color: $white !important;
  }
  .DayPicker-Day.DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
    color: #828282 !important;
    cursor: not-allowed;
    pointer-events: none;
  }
  .DayPicker-Day {
    border-radius: 0 !important;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #222222;
    width: 46px;

    &.DayPicker-Day--today {
      font-weight: normal;
    }
  }
  .DayPicker-Caption {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $black;

    & > div {
      font-size: 1em;
    }
  }
  .DayPicker-Weekday {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #828282;
  }
}

.react-tabs {
  &__tab-panel {
    display: none;

    &.react-tabs__tab-panel--selected {
      padding-top: 28px;
      display: block;
    }

    @include phone {
      padding-top: 10px;
    }
  }

  &__tab-list {
    margin: 0 -22px;
    padding: 0 22px;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > * {
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }

    @include phone {
      padding: 0 6px;
      flex-wrap: wrap;
      justify-content: center;
      grid-gap: 10px;

      & > * {
        margin-right: 14px;
      }
    }
  }

  &__tab {
    position: relative;
    padding-bottom: 9px;
    cursor: pointer;
    background: $tr;
    border: none;

    &.react-tabs__tab--selected {
      background: $tr;
      border: none;
      color: #002b97;

      span {
        color: $blue !important;
      }

      .dot {
        background: $blue !important;
      }

      .alert-icon {
        color: $blue;
      }

      &:after {
        opacity: 1;
      }
    }

    &.hasError {
      span,
      .dot,
      .alert-icon {
        color: $red !important;
      }
      &::after {
        border-bottom: 2px solid $red;
      }
    }

    &:after {
      position: absolute;
      content: '';
      bottom: -1px;
      left: 0;
      right: 0;
      border-bottom: 2px solid $blue;
      opacity: 0;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      color: #222222 !important;
    }

    .dot {
      position: absolute;
      top: 8px;
      right: -8px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #222222 !important;
    }

    .alert-icon {
      margin-left: 8px;
      line-height: 20px;
      top: 3px;
      position: relative;
    }

    .warning-icon {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

.vertical_tabs {
  display: grid;
  grid-template-columns: 200px auto;

  @include tablet {
    grid-template-columns: 150px auto;
  }

  &__tab-panel {
    display: none;
    padding: 30px;

    &.react-tabs__tab-panel--selected {
      display: block;
    }
  }

  &__tab-list {
    background: #f9f9f9;
  }

  &__tab {
    padding: 20px;

    &.react-tabs__tab--selected {
      border-left: 2px solid #025dff;
      background: #ffffff;
      font-weight: 500;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.load-item {
  width: 60px;
  height: 14px;
  background: #e7e7e7;
  animation: load-item 1.1s infinite;

  &._isHide {
    animation: none;
    opacity: 0;
  }
}

.ellipsis {
  display: flex;
  height: 14px;
  justify-content: space-between;
  align-items: center;
  max-width: 30px;
}

.circle {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: #f4f4f4;
  animation: load-item 1.1s infinite;
}

@keyframes load-item {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.8;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    display: flex;
    align-items: center;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: $tr;
}

::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 15px;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

/* переменный для отрисовки таблицы */
$checkbox: 20px;
$toggleStatus: 40px;
$name: 255px;
$name_full: calc(255px + 215px);
$show: 1.3fr;
$clicks: 1fr;
$installs: 1fr;
$cpc: 1fr;
$cpm: 1fr;
$ctr: 1fr;
$rate: 1.3fr;
$status: 0.6fr;
$budget: 1.3fr;
$actions: 1fr;

/* ширины для левой и правой части с учетом расширенного экрана*/
$widthLeftPartCampaignItem: 350px;
$widthLeftPartCampaignItemFull: 500px;
$widthLeftPartCampaignItemMobile: 300px;
$widthRightPartCampaignItem: 110px;
$widthRightPartCampaignItemMobile: 20px;

/* правила для построения строк в списке кампаний */
@mixin leftPartForCampaignItem {
  display: flex;
  align-items: center;
  grid-gap: 0 16px;
  width: $widthLeftPartCampaignItem;
  flex: none;

  @include tablet {
    width: $widthLeftPartCampaignItemMobile;
  }
}

@mixin mobileLeftSize {
  @include tablet {
    width: $widthLeftPartCampaignItemMobile;
  }
}

/* Рассчет ширины центральной части для блока Total. В остальных блоках мы не указываем */
@mixin calcWidthForCenterPart {
  width: calc(
    100% - 16px - 16px - #{$widthLeftPartCampaignItem} - #{$widthRightPartCampaignItem}
  );

  @include tablet {
    width: calc(
      100% - 16px - 16px - #{$widthLeftPartCampaignItemMobile} - #{$widthRightPartCampaignItemMobile}
    );
  }
}
/* Рассчет ширины центральной части для блока Total Extend. В остальных блоках мы не указываем */
@mixin calcWidthForCenterPartFull {
  width: calc(
    100% - 16px - 16px - #{$widthLeftPartCampaignItemFull} - #{$widthRightPartCampaignItem}
  );
  @include tablet {
    width: calc(
      100% - 16px - 16px - #{$widthLeftPartCampaignItemMobile} - #{$widthRightPartCampaignItemMobile}
    );
  }
}

@mixin centerPartForCampaignItem {
  display: grid;
  align-items: center;
  overflow-x: hidden;
  overflow-y: visible;
  gap: 0 10px;
  padding-right: 20px;
  flex: 1;
  scrollbar-color: rgba(255, 0, 0, 0) rgba(255, 0, 0, 0);
  transition: 0.3s;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  @include tablet {
    gap: 0 2px;
  }
}

@mixin rightPartForCampaignItem {
  display: flex;
  align-items: center;
  width: $widthRightPartCampaignItem;
  position: relative;
  justify-content: flex-end;
  flex: none;

  @include tablet {
    width: $widthRightPartCampaignItemMobile;
  }

  &::before {
    content: '';
    position: absolute;
    left: -39px;
    top: 0;
    height: 100%;
    width: 20px;
  }
}

/* Рассчет ширины столбцов для страницы со списком кампаний */
@mixin calcSizeForCampaignItem {
  @for $i from 1 to 100 {
    &:global(.size-#{$i}) {
      width: calc(80px + #{$i * 1}px); // было 80px * 7

      @include tablet {
        width: calc(50px + #{$i * 4}px);
      }
    }
  }
}

/* определение шаблона грида для линии столбцов к item кампании */
@mixin setGridTamplateForCampaignLine {
  @for $i from 1 to 100 {
    &:global(.length-#{$i}) {
      grid-template-columns: repeat($i, 1fr);

      @include tablet {
        grid-template-columns: repeat(calc(#{$i - 1}), 1fr);
      }
    }
  }
}

/* Стили для кнопки с пунктиром https://gyazo.com/fec93c229cc3717f34c2390ebb20f46d */
@mixin dashedBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 10px;
  color: #025dff;
  border: 1px dashed var(--Primary-Blue-20, #025dff);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: $light-blue;
  }

  &:disabled {
    border: 1px dashed $text-hint;
    color: $text-hint;

    &:hover {
      cursor: not-allowed;
      background: none;
    }

    svg {
      color: $text-hint;
    }
  }

  svg {
    transform: rotate(-45deg);
    color: #025dff;
  }
}
