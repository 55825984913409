@import 'src/styles/variables';

.list {
  padding-top: 28px;
  padding-bottom: 40px;

  @include tablet {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @include phone {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  & > * {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 20px;
  }
}
