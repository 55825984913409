@import 'src/styles/variables';

.card {
  background: #ffffff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  position: relative;
  //z-index: 4;

  &:hover {
    .resize {
      opacity: 1;
    }
  }
}

.resize {
  position: absolute;
  top: 0;
  left: calc(100% + 8px);
  width: 40px;
  height: 40px;
  background: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid $tr;
  border-radius: 1px;
  transition: all 0.1s ease;
  opacity: 0;

  @include laptop {
    display: none;
  }

  svg path {
    transition: stroke 0.1s;
  }

  &:hover {
    border: 1px solid #025dff;
    background: #e3ecff;
    cursor: pointer;

    svg path {
      stroke: #025dff;
    }
  }
}

.children {
  position: relative;
}

.tooltip {
  margin-left: 0;
}
