@import 'src/styles/variables';

.wrapper {
  display: block;
  width: 100%;
}

.dropzone {
  border: 1px solid #cbcbcb;
  background: $grey-bg;
  border-radius: 1px;
  padding: 28px;
  cursor: pointer;
  text-align: center;
  transition: border-color 0.1s;

  &:global(.isDisable) {
    pointer-events: none;
    border: 1px solid #cbcbcb;
    cursor: default;

    .text {
      color: $text-hint;
    }
  }

  &:hover {
    border: 1px solid $blue;

    .text {
      color: $blue;
    }

    svg {
      color: $blue;
    }
  }

  svg {
    color: $text-hint;
    transition: color 0.1s;
  }

  & .text {
    font-size: 13px;
    line-height: 20px;
    color: $text-hint;
    text-align: center;
    white-space: pre-line;
    transition: color 0.1s;
  }
}

.result {
  margin-top: 20px;
  display: block;
  width: 100%;
  padding: 8px 14px;
  border-radius: 1px;
  background-color: $blue;
  color: $white;
  font-size: 14px;
  line-height: 22px;

  &:global(.isSuccess) {
    background-color: $green;
  }
  &:global(.isError) {
    background-color: $red;
  }

  svg {
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
  }
}

.container {
  padding-top: 28px;

  & .thumb {
    padding-top: 8px;

    &:first-child {
      padding-top: 0;
    }

    &:global(.isError) {
      .inner {
        & .preview {
          border: 1px solid $red;

          & .status {
            background-color: $red;
          }
        }

        & .text {
          & .error {
            display: block;
          }
        }
      }
    }

    &:global(.isSuccess) {
      .inner {
        & .preview {
          border: 1px solid $green;

          & .status {
            background-color: $green;
          }
        }
      }
    }

    &:global(.isUpload) {
      .inner {
        & .progress {
          display: block;
        }
      }
    }

    & .inner {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      & .preview {
        margin-right: 24px;
        width: 56px;
        height: 56px;
        border: 1px dashed $text-hint;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        & .type {
          text-transform: uppercase;
          color: $text-hint;
          text-align: center;
          font-size: 13px;
          line-height: 24px;
          font-weight: 500;
          z-index: 1;
        }

        & .status {
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
        }
      }

      & .text {
        padding-top: 5px;
        width: 320px;

        & .filename {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: $black;
          word-break: break-word;
        }
        & .size {
          font-size: 13px;
          line-height: 20px;
          color: $black;
        }
        & .error {
          padding-top: 4px;
          display: none;
          font-size: 13px;
          line-height: 16px;
          color: $red;
          vertical-align: middle;

          svg {
            margin-right: 8px;
            margin-bottom: -3px;
            display: inline-block;
          }
        }
      }
      & .progress {
        margin-top: 24px;
        display: none;
        flex: 1 0 auto;
        width: calc(100% - 400px);
        height: 4px;
        border-radius: 15px;
        background-color: #ddd;
        position: relative;

        & .bar {
          height: 4px;
          border-radius: 15px;
          background-color: #ddd;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $blue;
        }
      }
    }
  }
}
