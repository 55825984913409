@import 'src/styles/variables';

.popup {
  display: none;
  position: fixed;
  top: 140px;
  left: calc(50% + 320px);
  transform: translateX(-50%);
  width: 540px;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
  z-index: 1001;

  &:global(.isOpen) {
    display: block;
  }

  & .header {
    display: flex;
    align-items: center;
    padding: 16px 16px 0;
    position: relative;

    & .filter {
      margin-right: 18px;
      display: flex;
      align-items: center;
      position: relative;
      width: 110px;
      height: 40px;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      border-radius: 1px;
      pointer-events: none;

      input {
        border: none;
        display: block;
        width: 100%;
        padding-left: 10px;
        padding-right: 32px;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $black;

        &::placeholder {
          color: #dddddd;
        }
      }

      & .close {
        position: absolute;
        top: calc(50% - 9px);
        right: 8px;
      }
    }

    & .search {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      position: relative;
      height: 40px;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      border-radius: 1px;

      &:active,
      &:focus-within {
        border: 1px solid $blue;
      }

      & .input {
        display: block;
        width: 100%;

        input {
          border: none;
          display: block;
          width: 100%;
          padding-left: 40px;
          padding-right: 132px;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: $black;
          z-index: 1;

          &::placeholder {
            color: #dddddd;
          }
        }
      }

      & .icon {
        position: absolute;
        top: calc(50% - 12px);
        left: 8px;
      }

      & .clear {
        position: absolute;
        top: calc(50% - 11px);
        right: 8px;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: #828282;
        z-index: 2;
        cursor: pointer;
        transition: color 0.1s;

        &:hover {
          color: $blue;
        }
      }
    }

    & .close {
      margin-left: 16px;
      display: block;
      flex: none;
      z-index: 2;
      cursor: pointer;
      transition: color 0.1s;

      &:hover {
        color: $blue;
      }
    }
  }

  & .result {
    padding: 10px 0;
  }
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;

  & .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 3px;
    background-color: #c4c4c4;
    animation: dots linear alternate infinite 0.3s;

    &:nth-child(2) {
      animation-delay: 0.1s;
    }

    &:nth-child(3) {
      animation-delay: 0.2s;
    }
  }
}

@keyframes dots {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.3;
  }
}

.empty {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #828282;
  padding: 18px 0;
}

.item {
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: background 0.1s;

  &:hover {
    opacity: 1;
    background-color: $grey-bg;
  }

  &:first-child {
    margin-top: 0;
  }

  & .icon {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $white;
    margin-right: 16px;
  }

  & .name {
    flex: 1 0 auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & .id {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: #828282;
    flex: 1 0 auto;
  }
}

.tags {
  padding: 0 16px 16px;
  max-height: 215px;
  overflow-y: scroll;
  .tag {
    margin: 4px;
    background: #ebebeb;
    border-radius: 1px;
    padding: 5px 16px;
    display: inline-block;
    font-size: 13px;
    line-height: 17px;
    max-width: calc(100% - 20px);
    letter-spacing: 0.03em;
    color: #222;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    cursor: pointer;
    transition: 0.5s background;
    &:hover {
      background: darken(#ebebeb, 10);
    }
    &:global(._isActive) {
      background: transparent;
      color: #025dff;
      border: 1px solid #025dff;
      transition: 0.5s;
      &:hover {
        background: #025dff;
        color: #fff;
      }
    }
  }
}
