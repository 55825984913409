.calendar-navbar {
  position: absolute;
  top: calc(50% - 12px);
  left: -25px;
  right: -25px;

  button {
    position: absolute;

    &:nth-child(1) {
      left: 0;
    }
    &:nth-child(2) {
      right: 0;
    }
  }
}
