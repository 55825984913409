.tooltip {
}

.google-visualization-tooltip {
  padding: 16px !important;
  border: none !important;
  box-shadow: 0px 7px 50px rgba(0, 0, 0, 0.12) !important;
  border-radius: 1px !important;
  background: #ffffff;
}

.google-visualization-tooltip strong {
  font-family: Rubik;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.google-visualization-tooltip span {
  display: block;
  font-family: Rubik;
  font-size: 14px;
  line-height: 22px;
  color: #025dff;
  margin-top: 4px;
}
