@import 'src/styles/variables';

.scroll-to-top-button {
  position: fixed;
  bottom: 5px;
  right: 9px;
  z-index: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #222222;
  opacity: 0.4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: translateY(100px);
  transition: transform 0.1s, opacity 0.1s;
  pointer-events: none;
  cursor: pointer;

  &.isVisible {
    transform: translateY(0);
    pointer-events: all;
    z-index: 10000;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.8;
  }

  svg {
    color: $white;
  }
}
