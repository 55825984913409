@import 'src/styles/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.loaderClient {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 774px;
  width: 100%;
  padding: 28px;
}

.title {
  flex: 1 0 auto;
  max-width: calc(100% - 60px);
}
.close {
  margin-left: 24px;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f4f5f6;
  justify-content: center;
  align-items: center;

  &:global(.isDisable) {
    cursor: default;
    pointer-events: none;
  }

  svg {
    cursor: pointer;
    color: $black;
  }
}

.form {
  padding-top: 32px;

  form > * {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.buttons {
  margin-top: 40px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  button {
    &:global(.loading) {
      border-color: transparent;
      background: #dadce0;

      & :global(.loader) {
        background: transparent;
        width: 36px;
        height: 36px;
      }
    }
  }
}
