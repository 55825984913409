@import 'src/styles/variables';

.modal {
  z-index: 90001;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.title {
  flex: 1 0 auto;
  max-width: calc(100% - 60px);
}
.close {
  margin-left: 24px;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f4f5f6;
  justify-content: center;
  align-items: center;

  svg {
    cursor: pointer;
    color: $black;
  }
}

.image {
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  & .image-preview {
    width: 56px;
    height: 56px;
    margin-right: 24px;
    border-radius: 50%;
    border: 1px dashed $text-hint;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: $text-hint;
    }
  }
  & .image-text {
    padding-top: 5px;
    max-width: calc(100% - 80px);
    text-align: left;
  }
  & .image-links {
    font-size: 14px;
    line-height: 22px;
    color: #828282;

    a {
      color: $blue;

      &:hover {
        text-decoration: underline;
        opacity: 1;
      }
    }

    span {
      margin: 0 4px;
    }
  }
  & .image-description {
    padding-top: 4px;

    p {
      font-size: 13px;
      line-height: 20px;
      color: #828282;
    }
  }
}

.form {
  padding-top: 32px;

  form > * {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.buttons {
  margin-top: 40px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
