@import 'src/styles/variables';

.tooltipPoratal {
  .body {
    svg {
      vertical-align: middle;
    }

    &:hover {
      svg {
        color: $blue;
      }
    }
  }

  &:global(._isDisabled) {
    cursor: not-allowed !important;

    .body {
      pointer-events: none !important;
    }

    svg {
      color: #828282 !important;
    }

    &:hover {
      &__title {
        svg {
          color: #828282 !important;
        }
      }
    }
  }
}

.hint {
  position: fixed;
  z-index: -1;
  opacity: 0;
  padding: 8px 14px;
  box-shadow: 0px 7px 50px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  background: #222;
  color: #fff;
  text-transform: none;
  width: max-content;
  height: fit-content;
  transform: translateX(50%);

  &:global(._isOpen) {
    opacity: 1;
    z-index: 90001;
  }

  @for $i from 1 to 1000 {
    &:global(._isCorrectLeft-#{$i}) {
      transform: translateX(0);
      right: 0;
      &::after {
        left: auto;
        right: #{$i}px;
        transform: none;
      }
    }
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #222 $tr $tr $tr;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.12));
    background: $tr;
    z-index: -1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    width: 100%;
    height: 12px;
    left: 0;
    z-index: 0;
  }

  &:global(._isLeft) {
    transform: none;
    &:before {
      top: 0;
      height: 100%;
      width: 12px;
      left: auto;
      right: 0;
    }
    &:after {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-width: 10px 0 10px 10px;
      border-color: $tr $tr $tr #222;
    }
  
  }

  &:global(._isWhite) {
    background: #fff;
    color: #000;

    &:after {
      border-color: #fff $tr $tr $tr;
    }

    &:global(._isLeft) {
      &:after {
        border-color: $tr $tr $tr #fff;
      }
    }
  }

  &:global(._isCorrectTop) {
    &:after {
      top: auto;
      bottom: 100%;
      transform: translate(-50%, 0) rotate(180deg);
    }
  }
}
